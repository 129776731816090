<template>
  <div class="o-layout o-layout--flush o-layout--middle c-list__row c-list__row-dashboard">

    <div :class="{active: panel.activeRotation === rotation.index}"
         class="o-layout__item u-1-of-1 u-2-of-8-at-small c-list__main-cell u-padding-right-small">
      {{panel.name}}
      <span class="c-list__main-cell-span" v-if="panel.exerciseTypeId">{{$t('exercise.type.'+ panel.exerciseTypeId)}}</span>
    </div>

    <div class="o-layout__item u-1-of-1 u-4-of-8-at-small c-list__main-cell c-list__cell-dashboard-exercises u-padding-vertical-tiny"
         v-if="passes.length">
      <div class="c-exercise-pass js-passes" v-for="(pass, p_i) in passes" :key="p_i">
        <div v-for="blockPart in pass.participations" :key="blockPart.participationId+'-'+pass.exerciseTypeId"
             class="c-exercise-pass__exercise js-exercise" :class="getExerciseStatus(blockPart, pass.exerciseTypeId)">
        </div>
      </div>
    </div>

    <div class="o-layout__item u-1-of-1 u-2-of-8-at-small c-list__main-cell u-text-right">
      <button class="c-button c-button--primary c-button--exercise"
           v-if="passes.length" v-on:click="viewBlock()">
        {{$t('block.exercises')}}
      </button>
    </div>

  </div>
</template>

<script>
  import filter from 'lodash/filter';
  import find from 'lodash/find';

  import blockLib from 'client/lib/block.js';

  export default {
    name:'panel-dashboard',
    props: ['session', 'rotation', 'panel'],
    colors: {
      absent: {
        class: 'c-exercise-pass__exercise--other',
        color: '#999',
        label: 'absent/reserve'
      },
      dns: {
        class: 'c-exercise-pass__exercise--other',
        color: '#666',
        label: 'dns'
      },
      finished: {
        class: 'c-exercise-pass__exercise--finished',
        color: 'blue',
        label: 'finished'
      },
      open: {
        class: 'c-exercise-pass__exercise--active',
        color: 'orange',
        label: 'open'
      },
      scheduled: {
        class: 'c-exercise-pass__exercise--scheduled',
        color: 'red',
        label: 'scheduled'
      },
      missing: {
        class: 'c-exercise-pass__exercise--other',
        color: 'white',
        label: 'missing'
      },
      filler: {
        class: 'c-exercise-pass__exercise--other',
        color: 'white',
        label: 'missing'
      }
    },
    data: function() {
      return {
        passes: [],
      }
    },
    computed: {
      categories: function() {
        return this.$store.state.categories.items;
      },
      blockParticipations: function() {
        return filter(this.$store.state.blockParticipations.items, item => {
          return item.sessionId === this.session.id;
        });
      },
      participations: function() {
        return this.$store.state.participations.items;
      },
      exercises: function() {
        return this.$store.state.exercises.items;
      }
    },
    created: function() {
      this.init();
    },
    updated: function() {
      this.setLayoutPasses();
    },
    watch: {
      blockParticipations: {
        handler: function () {
          this.init();
        },
        deep: true
      }
    },
    methods: {
      init: function() {
        if (this.panel) {
          this.passes = blockLib.getPanelOrder(this.panel, this.rotation.index);
        }
      },
      setLayoutPasses: function() {
        if (this.passes && this.passes.length === 1) {
          let passesGroup = document.querySelectorAll('.js-passes');


          passesGroup.forEach((element) => {

            let widthContainer = element.offsetWidth;
            let totalExercises = element.querySelectorAll('.js-exercise').length;
            const exerciseSize = 17;
            let totalRows = this.calculateRowsInPasses(widthContainer, totalExercises, exerciseSize);

            // element.dataset.rows = totalRows;


            if (totalRows > 1) {
              let exercisesInPasses = element.querySelectorAll('.js-exercise');

              exercisesInPasses.forEach((elementExercise, indexExercise) => {
                // let totalExercises = arrayExercise.length;
                let exercisesPerRow = Math.ceil(totalExercises / totalRows);
                // console.log('ex per row', widthContainer, ' - ', exercisesPerRow);

                if (indexExercise >= 1 && indexExercise % exercisesPerRow === 0) {
                  elementExercise.parentNode.classList.add('c-exercise-pass--break');
                  elementExercise.classList.add('c-exercise-pass__exercise-break');
                }
              });
            }

          });
        }
      },
      calculateRowsInPasses: function(totalWidth, totalSquares, squareSize) {
          const neededWidth = totalSquares * squareSize;
          const neededRows = Math.ceil(neededWidth / totalWidth);

          return neededRows;
      },
      getParticipation: function(participationId) {
        return find(this.participations, item => { return item.id === participationId; });
      },
      getExerciseStatus: function(blockParticipation, exerciseTypeId) {
        if (blockParticipation.filler) {
          return 'c-exercise-pass__exercise--filler';
        }

        const participation = this.getParticipation(blockParticipation.participationId);
        const partRound = find(participation.rounds, r => r.roundIndex === this.session.roundIndex)

        if (! partRound) {
          console.log('panel dashboard', participation)
        }

        if (partRound?.status !== 'present') {
          return 'c-exercise-pass__exercise--other';
        }

        if (exerciseTypeId === 'default') {
          exerciseTypeId = blockParticipation.exerciseTypeId;
        }

        const exercise = find(this.exercises, item => blockParticipation.participationId === item.participationId &&
              this.session.roundIndex === item.roundIndex &&
              exerciseTypeId === item.exerciseTypeId
        )

        if (! exercise) {
          return 'c-exercise-pass__exercise--other';
        }

        return this.$options.colors[exercise.status] ? this.$options.colors[exercise.status].class : 'missing';
      },
      viewBlock: function() {
        this.$navigation.goto({name: 'admin.event.discipline.dashboard.block',
          params: {panelId: this.panel.id, rotation: this.rotation.index}});
      }
    }
  };
</script>

<style scoped>

</style>
