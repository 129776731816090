<template>
  <div class="c-main">
    <form action="#" class="c-main--flex-1 u-margin-bottom-small">
      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-medium">
          <sceTextField v-model="name" name="name" label="name"
                     v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('name')}" />
          <sceTextField v-model="externalId" name="externalId" label="externalId" />
          <sceTextField v-model="competitionId" name="competitionId" label="category.competitionId" />
          <sceTextArea v-model="description" name="description" label="notes" rows="2" />
        </div>

        <div class="o-layout__item  u-1-of-2-at-medium">
          <div class="o-layout">
            <div class="o-layout__item u-1-of-2-at-small">
              <sceNumberField v-model.number="minAge" name="minAge" label="category.minimalAge"
                         v-validate data-vv-rules="required|numeric|min_value:0|max_value:120"
                         :class="{'is-invalid': errors.has('minAge')}" min="0" max="120" step="1" />
            </div>
            <div class="o-layout__item u-1-of-2-at-small">
              <sceNumberField v-model.number="maxAge" name="maxAge" label="category.maximalAge"
                           v-validate data-vv-rules="required|numeric|min_value:0|max_value:120"
                           :class="{'is-invalid': errors.has('maxAge')}" min="0" max="120" step="1" />
            </div>
            <div class="o-layout__item u-1-of-2-at-small">
              <sceSelect v-model="gender" name="gender" label="gender"
                         :options="$options.genderOptions" v-validate data-vv-rules="required"
                         :class="{'is-invalid': errors.has('gender')}"/>
            </div>
            <div class="o-layout__item u-1-of-2-at-small">
              <sceSelect v-model="participantType" name="participantType" label="participantType"
                         :options="$options.participantTypeOptions"
                         v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('participantType')}"/>
            </div>
            <div class="o-layout__item u-1-of-2-at-small" v-if="participantType === 'group'">
              <sceNumberField v-model.number="groupSize.min" name="groupSizeMin" label="category.minimalGroupSize"
                              v-validate data-vv-rules="numeric|min_value:0|max_value:100"
                              :class="{'is-invalid': errors.has('groupSizeMin')}" min="0" max="100" step="1" />
            </div>
            <div class="o-layout__item u-1-of-2-at-small" v-if="participantType === 'group'">
              <sceNumberField v-model.number="groupSize.max" name="groupSizeMax" label="category.maximalGroupSize"
                              v-validate data-vv-rules="numeric|min_value:0|max_value:100"
                              :class="{'is-invalid': errors.has('groupSizeMax')}" min="0" max="100" step="1" />
            </div>
          </div>
        </div>
      </div>

      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="scorePublication" name="scorePublication" label="scorePublication"
                     :options="$options.scorePublicationOptions" v-validate data-vv-rules="required" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="rankingPublication" name="rankingPublication" label="rankingPublication"
                     :options="$options.rankingPublicationOptions" v-validate data-vv-rules="required" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceMultiSelect v-model="includeCategories" :options="getIncludeCategories()" name="includeCategories"
                          label="includeCategory" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="warmup" :name="'warmup'+1" label="session.warmupConfig" :options="warmupOptions"
                     :config="{optionId: 'name', emptyLabel: 'none', localized: false}" />
        </div>
      </div>

      <div v-for="(round, round_i) in categoryRounds" :key="round.roundIndex" >
        <h3 class="c-title c-title--page-section">{{round.name}}</h3>
        <div class="o-layout">
          <div class="o-layout__item u-1-of-2-at-medium">
            <sceSelect v-model="round.formatId" :name="'format-'+round_i" label="competitionFormat" :options="formats"
                       v-validate :data-vv-rules="round_i === 0 ? 'required' : ''"
                       :class="{'is-invalid': errors.has('format-'+round_i)}" v-on:change="setRoundOptions(round)"
                       :config="{groups: true, localized: false, emptyLabel: 'category.skipRound'}" />
          </div>
          <div class="o-layout__item u-1-of-2-at-medium o-flex o-flex--align-end">
            <div class="c-input--extra-info">
              {{getFormatDescription(round.formatId)}}
            </div>
          </div>
          <div class="o-layout__item u-1-of-2-at-medium">
            <sceSelect v-model="round.tieBreakId" :name="'tie-'+round_i" label="tieBreak"
                       :options="[{id: null, name: 'none'}, ...tieBreaks]"/>
          </div>
          <div class="o-layout__item u-1-of-2-at-medium o-flex o-flex--align-end">
            <div v-if="round.formatId" class="c-input--extra-info">
              {{getTieBreakDescription(round.tieBreakId)}}
            </div>
          </div>
          <div class="o-layout__item u-1-of-2-at-medium">
            <sceNumberField :name="'subDivision-'+round_i" :class="{'is-invalid': errors.has('subDivision-'+round_i)}"
                            v-model.number="round.subDivisions" label="category.subDivisionsNr" min="1" max="20" step="1"
                            v-validate data-vv-rules="required|numeric|min_value:1|max_value:20"/>
          </div>
          <div class="o-layout__item u-1-of-2-at-medium" v-if="hasTeamRankings(round)">
            <sceSelect v-model="round.teamMethod" :name="'teamMethod-'+round_i" label="category.teamMethod"
                       :options="$options.teamMethodOptions" :class="{'is-invalid': errors.has('teamMethod-'+round_i)}"
                       v-validate data-rules="required" />
            <sceNumberField v-model="round.teamCount" :name="'teamCount-'+round_i"
                            :label="'category.teamCount.' + round.teamMethod" min="0" max="100" step="1"
                            v-validate data-rules="required|numeric|min_value:0|max_value:100"
                            :class="{'is-invalid': errors.has('teamCount-' + round_i)}" />
          </div>
          <div class="o-layout__item u-1-of-2-at-small">
            <sceCheckBox v-model="round.requireAllEvents" :name="'allEvents-'+round_i" label="requireAllEvents" />
          </div>

          <div class="o-layout__item u-1-of-2-at-medium" v-for="field of round.customFields" :key="field.config.label">
            <div v-if="field.config.inputType !== 'copy'" :is="'custom-field-' + field.config.inputType"
                 v-model="field.value" :name="'round-'+ round_i + '-' + field.config.label" :config="field.config"/>
          </div>
        </div>

        <template v-if="round_i > 0">
          <h4 class="c-title c-title--page-section">{{$t('final_result')}}</h4>
          <div class="o-layout">
            <div v-for="(rt, type) of round.results" :key="'results-' + type" class="o-layout__item u-1-of-2-at-medium">
              <sceSelect v-model="rt.result" :name="'result-'+round_i + '-'+ type" :label="'rankingType.' + type"
                         :options="$options.roundResultOptions" :class="{'is-invalid': errors.has('result-'+round_i + '-'+ type)}"
                         v-validate data-rules="required" />
            </div>
          </div>

          <h4 class="c-title c-title--page-section">{{$t('categoryRound.selectionCriteria')}}</h4>
          <div class="o-layout">
            <div class="o-layout__item u-1-of-4-at-small">
              <sceNumberField v-model.number="round.selectionCriteria.maxCount" min="0" max="1000" step="1"
                              label="categoryRound.selectionCriteria.count" :name="'selection-count-'+round_i"
                              v-validate data-vv-rules="required|numeric|min_value:0|max_value:1000"
                              :class="{'is-invalid': errors.has('selection-count-'+round_i)}" />
            </div>
            <div class="o-layout__item u-1-of-4-at-small">
              <sceNumberField v-model.number="round.selectionCriteria.reserveCount" min="0" max="1000" step="1"
                              label="categoryRound.selectionCriteria.reserves" :name="'selection-reserves-'+round_i"
                              v-validate data-vv-rules="required|numeric|min_value:0|max_value:1000"
                              :class="{'is-invalid': errors.has('selection-reserves-'+round_i)}" />
            </div>
            <div class="o-layout__item u-1-of-4-at-small">
              <sceNumberField v-model.number="round.selectionCriteria.minScore" min="0" max="9999" step="0.001"
                              label="categoryRound.selectionCriteria.minScore" :name="'selection-min-'+round_i"
                              v-validate data-vv-rules="required|decimal|min_value:0|max_value:9999"
                              :class="{'is-invalid': errors.has('selection-min-'+round_i)}" />
            </div>
            <div class="o-layout__item u-1-of-4-at-small">
              <sceSelect v-model.number="round.selectionCriteria.clubCount" :name="'selection-clubCount-'+round_i"
                         label="categoryRound.selectionCriteria.clubMax"
                         :options="[{id: 0, name: $t('unlimited')}, {id: 1, name: '1'}, {id: 2, name: '2'}, {id: 3, name: '3'}]" />
            </div>
          </div>
        </template>

        <div class="o-layout">
          <div class="o-layout__item u-1-of-2-at-small">
            <h4 class="c-title c-title--page-section">{{$t('categoryRound.singleAwards')}}</h4>

            <div class="o-layout">
              <div class="o-layout__item">
                <sceSelect v-model="round.singleAwardsType" :name="'singleAwardsType-' + round.index"
                           :options="$options.awardTypes" :config="{emptyLabel: 'none'}" />
              </div>
            </div>
            <categoryAwards v-if="round.singleAwardsType"  :namePrefix="'singleAwards_'+round_i"
                            v-model="round.singleAwards" />
          </div>

          <div class="o-layout__item u-1-of-2-at-small" v-if="hasEventRankings(round)">
            <h4 class="c-title c-title--page-section">{{$t('categoryRound.eventAwards')}}</h4>

            <div class="o-layout">
              <div class="o-layout__item">
                <sceSelect v-model="round.eventAwardsType" :name="'eventAwardsType-' + round.index"
                           :options="$options.awardTypes" :config="{emptyLabel: 'none'}" />
              </div>
            </div>
            <categoryAwards v-if="round.eventAwardsType" :namePrefix="'eventAwards_'+round_i"
                            v-model="round.eventAwards" />
          </div>

          <div class="o-layout__item u-1-of-2-at-small" v-if="hasTeamRankings(round)">
            <h4 class="c-title c-title--page-section">{{$t('categoryRound.teamAwards')}}</h4>

            <div class="o-layout">
              <div class="o-layout__item">
                <sceSelect v-model="round.teamAwardsType" :name="'teamAwardsType-' + round.index"
                           :options="$options.awardTypes" :config="{emptyLabel: 'none'}" />
              </div>
            </div>
            <categoryAwards v-if="round.teamAwardsType" :namePrefix="'teamAwards_'+round_i"
                            v-model="round.teamAwards" />
          </div>

          <div class="o-layout__item u-1-of-2-at-small"
               v-if="discipline.exerciseTiming === 'category' || discipline.exerciseTiming === 'exercise'">
            <h4 class="c-title c-title--page-section">{{$t('category.exerciseTiming')}}</h4>

            <sceNumberField v-if="discipline.exerciseTiming === 'category'"
                            v-model.number="round.exerciseTime" :name="'exerciseTime-'+round_i"
                            label="session.schedule.exerciseTime" min="0" max="1200" step="1" />

            <div class="o-layout" v-if="discipline.exerciseTiming === 'exercise' && round.formatId">
              <div v-for="exercise of round.exerciseTiming" :key="exercise.type+'-'+round_i"
                   class="o-layout__item">
                <sceNumberField v-model.number="exercise.time" :name="'exerciseTime:'+round_i+':'+exercise.type"
                                :label="'exercise.type.'+exercise.type" min="0" max="1200" step="1" />
              </div>
            </div>
            <p v-if="discipline.exerciseTiming === 'exercise' && ! round.formatId">
              {{ $t('category.exerciseTiming.noFormat') }}
            </p>
          </div>
        </div>

        <div class="o-layout" v-if="discipline.allowTrackSelection">
          <div class="o-layout__item">
            <h4 class="c-title c-title--page-section">{{$t('categoryRound.trackSelection')}}</h4>
          </div>
          <div class="o-layout__item">
            <sceCheckBox v-model="round.trackSelection.enable" :name="'trackSelection-enable-'+round_i"
                         label="categoryRound.trackSelection.enable" />
          </div>
          <div v-if="round.trackSelection.enable" class="o-layout__item u-1-of-2-at-small">
            <sceNumberField v-model="round.trackSelection.minimumScore" :name="'minimumScore-'+round_i"
                            label="categoryRound.trackSelection.minimumScore" min="0" max="99999" step="0.001"
                            v-validate data-vv-rules="decimal|min_value:0|max_value:99999"
                            :class="{'is-invalid': errors.has('minimumScore-'+round_i)}"
            />
          </div>
          <div v-if="round.trackSelection.enable" class="o-layout__item u-1-of-2-at-small">
            <sceSelect v-model="round.trackSelection.requirementsCheck" :name="'requirementsCheck-'+round_i"
                       label="categoryRound.trackSelection.requirementsCheck" :options="round.exerciseTypes"
                       :config="{emptyLabel: 'none'}"/>
          </div>

        </div>
      </div>
    </form>
    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
        <sceButton v-if="id" class="c-button--secondary c-button--large" v-on:click="remove()" label="delete" />
      </div>

      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <sceRouteButton :route="{ name: 'admin.event.discipline.categories'}" label="cancel"
                        class="c-button--ghost c-button--large" />
        <sceButton class="c-button--primary c-button--large" v-on:click="submit()" label="save" />
      </div>
    </footer>
  </div>
</template>


<script>
  import user from 'client/lib/user'
  import categoryLib from 'client/lib/category'
  import customFieldsLib from 'client/lib/customFields'
  import options from 'client/lib/options'
  import rounds from 'client/lib/rounds'

  import clone from 'lodash/cloneDeep'
  import filter from 'lodash/filter'
  import find from 'lodash/find'
  import forOwn from 'lodash/forOwn'
  import map from 'lodash/map'
  import merge from 'lodash/merge'
  import sortBy from 'lodash/sortBy'

  export default {
    name: "category",
    components: {
      categoryAwards: require('client/components/models/awards.vue').default,
    },
    props: ['id'],
    ...options.category,
    data: function () {
      return {
        name: '',
        externalId: undefined,
        competitionId: undefined,
        description: '',
        index: -1,
        minAge: 0,
        maxAge: 120,
        participantType: 'clubMember',
        gender: 'mixed',
        groupSize: {min: undefined, max: undefined},
        scorePublication: 'totals',
        rankingPublication: 'full',
        categoryRounds: [],
        includeCategories: [],
        categoryOptions: {},
        warmup: undefined,
      };
    },
    computed: {
      discipline: function() {
        return this.$store.state.eventDiscipline.discipline;
      },
      category: function () {
        return find(this.$store.state.categories.items, (item) => {
          return item.id === this.id;
        });
      },
      categories: function() {
        return sortBy(this.$store.state.categories.items, 'index');
      },
      rounds: function() {
        return sortBy(rounds.getRounds(), 'index');
      },
      eventDiscipline: function() {
        return find(this.$store.state.eventDisciplines.items,
          item => item.id === this.$route.params.disciplineId);
      },
      formats: function() {
        let formats = []

        const activeFormats = sortBy(filter(this.$store.state.eventDiscipline.formats, format => {
          return user.matchEventTags(format) && ! format.deprecated;
        }), ['index']);
        if (activeFormats.length) {
          formats.push({
            name: this.$t('active'),
            elements: activeFormats,
          });
        }

        const inActiveFormats = sortBy(filter(this.$store.state.eventDiscipline.formats, format => {
          return user.matchEventTags(format) && format.deprecated;
        }), ['index']);
        if (inActiveFormats.length) {
          formats.push({
            name: this.$t('inactive'),
            elements: inActiveFormats,
          });
        }
        return formats;
      },
      tieBreaks: function() {
        return sortBy(filter(this.$store.state.eventDiscipline.tieBreaks,
          item => user.matchEventTags(item)), 'index')
      },
      warmupOptions: function() {
        let warmupOptions = []
        const eventDiscipline =
            find(this.$store.state.eventDisciplines.items,  i => i.id === this.$route.params.disciplineId)
        if (eventDiscipline.planningConfig && eventDiscipline.planningConfig.warmupConfigs) {
          warmupOptions = eventDiscipline.planningConfig.warmupConfigs
        }
        return warmupOptions
      },
    },
    watch: {
      category: {
        handler: function () {
          this.setCategory()
        },
        immediate: true,
      },
    },
    methods: {
      setCategory: function () {
        // prepare category rounds with default data
        this.categoryRounds = this.rounds.map(round => {
          const results = {}
          round.rankings.forEach(rt => {
            if (rt.enabled) {
              results[rt.rankingType] = { result: 'reset' }
            }
          })

          return {
            formatId: null,
            id: round.index,
            roundIndex: round.index,
            subDivisions: 1,
            name: round.name,
            singleAwardsType: 'score',
            singleAwards: [],
            eventAwardsType: 'score',
            eventAwards: [],
            teamCount: 3,
            teamMethod: 'event',
            teamAwardsType: 'score',
            teamAwards: [],
            tieBreakId: null,
            results,
            selectionCriteria: {
              maxCount: 0,
              reserveCount: 0,
              clubCount: 0,
              minScore: 0,
            },
            exerciseTime: null,
            exerciseTiming: [],
            customFields: [],
            requireAllEvents: false,
            trackSelection: {
              enable: false,
              minimumScore: 0,
              requirementsCheck: null,
            },
          }
        })

        // load category data
        if (this.category) {
          this.name = this.category.name
          this.externalId= this.category.accessId
          this.competitionId = this.category.competitionId
          this.description = this.category.description
          this.index = this.category.index
          this.description = this.category.description
          this.minAge = this.category.minAge
          this.maxAge = this.category.maxAge
          this.participantType = this.category.participantType
          this.gender = this.category.gender
          this.groupSize = this.category.groupSize || {min: undefined, max: undefined}
          this.scorePublication = this.category.scorePublication
          this.rankingPublication = this.category.rankingPublication
          let includeCategories = []
          if (this.category.includeCategories) {
            includeCategories = clone(this.category.includeCategories)
          } else if (this.category.includeCategoryId) {
            includeCategories = [this.category.includeCategoryId]
          }
          this.includeCategories = map(includeCategories, cat => find(this.categories, item => item.id === cat))

          let warmup = this.category.warmup
          const warmupOptions = this.warmupOptions
          if (warmup) {
            if (! find(warmupOptions, w => w.name === warmup)) {
              warmup = null
            }
          }
          if (! warmup && warmupOptions.length) {
            warmup = warmupOptions[0].name
          }
          this.warmup = warmup

          // Load category round settings into presets
          this.categoryRounds.forEach(cr => {
            const source = this.category.rounds?.find(r => r.roundIndex === cr.roundIndex)
            if (source) {
              const results = clone(cr.results)
              if (source.results) {
                forOwn(source.results, (val, key) => {
                  results[key] = {
                    result: val
                  }
                })
              }

              merge(cr, source)
              this.setRoundExerciseTypes(cr)
              cr.results = results
              cr.customFields = this.getRoundOptions(cr)
              if (cr.formatId && this.discipline.exerciseTiming === 'exercise') {
                cr.exerciseTiming = map(cr.exerciseTypes, exerciseType => {
                  const time = find(source.exerciseTiming, time => time.type === exerciseType.id)
                  return time ? time : {type: exerciseType.id, time: undefined}
                })
              }
            }
          })
        }
      },
      getRoundOptions: function(round) {
        let options = []
        const format = find(this.$store.state.eventDiscipline.formats, item => item.id === round.formatId)

        if (this.discipline.options) {
          options = this.discipline.options
        }
        if (format?.options) {
          options = options.concat(format.options)
        }
        return customFieldsLib.getFields(options, round.config, format)
      },
      setRoundOptions: function(round) {
        round.customFields = this.getRoundOptions(round)
        this.setRoundExerciseTypes(round)
      },
      setRoundExerciseTypes: function(round) {
        let exerciseTypes = []
        if (round.formatId) {
          const format = find(this.$store.state.eventDiscipline.formats, i => i.id === round.formatId)
          exerciseTypes = categoryLib.getExerciseTypes(format)
        }
        round.exerciseTypes = exerciseTypes.map(e => ({
          id: e,
          name: this.$t('exercise.type.' + e)
        }))
      },
      getIncludeCategories: function() {
        return filter(this.categories, item => item.id !== this.id);
      },
      hasTeamRankings: function(categoryRound) {
        const round = find(this.rounds, r => r.roundIndex === categoryRound.roundIndex)
        if (round === undefined) return false

        const ranking = find(round.rankings, item => item.rankingType === 'TEAM-AA')
        return ranking && ranking.enabled
      },
      hasEventRankings: function(categoryRound) {
        const round = find(this.rounds, r => r.index === categoryRound.roundIndex)
        if (round === undefined) { return false }

        const ranking = find(round.rankings, i => i.rankingType === 'EVENTS')
        return ranking?.enabled
      },
      submit: function () {
        this.$validator.validateAll().then(() => {
          if (!this.errors.any()) {
            let rounds = map(this.categoryRounds, item => {
              const options = customFieldsLib.getValues(item.customFields)

              const results = {}
              forOwn(item.results, (val, type) => {
                results[type] = val.result
              })
              return {
                categoryId: item.categoryId,
                formatId: item.formatId,
                id: item.id,
                roundIndex: item.roundIndex,
                subDivisions: item.subDivisions,
                singleAwardsType: item.singleAwardsType,
                singleAwards: item.singleAwards,
                eventAwardsType: item.eventAwardsType,
                eventAwards: item.eventAwards,
                teamCount: item.teamCount,
                teamMethod: item.teamMethod,
                teamAwardsType: item.teamAwardsType,
                teamAwards: item.teamAwards,
                tieBreakId: item.tieBreakId,
                results,
                selectionCriteria: item.selectionCriteria,
                config: options,
                exerciseTime: item.exerciseTime,
                exerciseTiming: item.exerciseTiming,
                requireAllEvents: item.requireAllEvents,
                trackSelection: item.trackSelection,
              }
            });

            console.log(rounds)
            const data = {
              id: this.id,
              name: this.name,
              accessId: this.externalId,
              competitionId: this.competitionId,
              description: this.description,
              index: this.index,
              minAge: this.minAge,
              maxAge: this.maxAge,
              scorePublication: this.scorePublication,
              rankingPublication: this.rankingPublication,
              participantType: this.participantType,
              gender: this.gender,
              groupSize: this.groupSize,
              rounds,
              includeCategories: map(this.includeCategories, item => item.id),
              warmup: this.warmup,
            };

            this.$emit('submit', data);
          } else {
            this.$notify.warning('Validation errors');
          }
        });
      },
      remove: function () {
        this.$modal.show({
          title: this.$t('delete.confirm.title'),
          message: this.$t('delete.confirm.text', {item: this.name}),
          onConfirm: () => {
            this.$emit('remove', this.category);
          }
        });
      },
      getFormatDescription: function(formatId) {
        const format = find(this.$store.state.eventDiscipline.formats, item => item.id === formatId);
        return format ? format.description : this.$t('category.skipRound.description');
      },
      getTieBreakDescription: function(tieBreakId) {
        if (tieBreakId) {
          return find(this.tieBreaks, function (item) {
            return item.id === tieBreakId;
          }).description;
        }

        return 'Skip tie-break';
      },
    }
  };
</script>
