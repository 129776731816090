<template>
  <router-view></router-view>
</template>

<script>
	export default {
		name: "sessionPanels-wrapper",
	};
</script>

<style scoped>
</style>
